
import state from "./moduleConstructionUnitStatusState.js"
import mutations from "./moduleConstructionUnitStatusMutations.js"
import getters from "./moduleConstructionUnitStatusGetters.js"
import actions from './moduleConstructionUnitStatusActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
