<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
    class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? $t('AddNew') : $t('Update') }} {{ $t('Item') }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">
      <vs-card title="icon">
        <h4>{{ $t('NameAr') }}</h4>
        <vs-input class="w-full" v-model="nameAr" v-validate="'required'" name="NameAr" />
        <h4>{{ $t('NameEn') }}</h4>
        <vs-input class="w-full" v-model="nameEn" v-validate="'required'" name="NameEn" />
      </vs-card>

    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData">{{ $t('Submit') }}</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{ $t('Cancel') }}</vs-button>
    </div>

  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moduleConstructionUnitStatus from "@/store/ConstructionUnitStatus/moduleConstructionUnitStatus.js";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      //Countries:[],
      default: () => { }
    }
  },
  data() {
    return {
      ID: null,
      ShowOffersWithNoRestrict: false,
      nameAr: '',
      nameEn: '',
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { ID, nameAr, nameEn } = JSON.parse(
          JSON.stringify(this.data)
        );
        this.Id =ID;
        this.nameAr = nameAr;
        this.nameEn = nameEn;
      }
    }
  },


  computed: {
    isSidebarActiveLocal: {

      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    Countries() {
      debugger;
      return this.$store.state.CountryList.Countries;
    },
    isFormValid() {
      return !this.errors.any() && this.Id;
    }
  },

  methods: {
    initValues() {
      if (this.data.ID) return;
      this.Id = null;
      this.ShowOffersWithNoRestrict = false;
      this.nameAr = '';
      this.nameEn = '';
    },

    submitData() {
      debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            Id: this.ID,
            nameAr: this.nameAr,
            nameEn: this.nameEn,
          };

          if (this.Id !== null && this.Id >= 0) {
            this.$store.dispatch("UintTypeList/updateItem", obj).then(() => {
              this.$store
                .dispatch("ConstructionUnitStatusList/fetchDataListItems")
              window.showSuccess();
            }).catch(err => {
              console.error(err);
            });
          } else {
            debugger;
            delete obj.Id;
            obj.popularity = 0;
            this.$store.dispatch("UintTypeList/addItem", obj).then(() => {
              this.$store
                .dispatch("ConstructionUnitStatusList/fetchDataListItems")
              window.showSuccess();
            }).catch(err => {
              console.error(err);
            });
          }

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },


  },

  components: {
    VuePerfectScrollbar
  },
  create(){
    if (!moduleConstructionUnitStatus.isRegistered) {
        this.$store.registerModule("ConstructionUnitStatusList", moduleConstructionUnitStatus);
        moduleConstructionUnitStatus.isRegistered = true;
      }

  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
