import axios from "@/axios.js";

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("API/Lookup/AddUintConstructionStatus", item)
        .then(response => {
          debugger;
          resolve(response);
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.data.ID })
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("API/Lookup/GetAllUintConstructionStatus")
        .then(response => {
          resolve(response);
          commit("SET_ConstructionUnitStatus", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateItem(context, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("API/Lookup/UpdateUintConstructionStatus", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID(context, itemid) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/Lookup/GetUintDeliverystatusById?Id=" + itemid)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteObject({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("API/Lookup/DeleteUintConstructionStatus?Id=" + item.ID)
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item.ID);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UploadImage(context, image) {
    const formData = new FormData();
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
