export default {
  ADD_ITEM(state, item) {
    state.ConstructionUnitStatus.unshift(item);
  },
  SET_ConstructionUnitStatus(state,  ConstructionUnitStatus) {
    state.ConstructionUnitStatus =  ConstructionUnitStatus;
  },
  UPDATE_ConstructionUnitStatus(state,  ConstructionUnitStatus) {
    const  ConstructionUnitStatusIndex = state.ConstructionUnitStatus.findIndex(p => p.ID ==  ConstructionUnitStatus.ID);
    if ( ConstructionUnitStatusIndex != -1) {
      Object.assign(state. ConstructionUnitStatus[ ConstructionUnitStatusIndex],  ConstructionUnitStatus);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state. ConstructionUnitStatus.findIndex(p => p.ID == itemId);
    state.ConstructionUnitStatus.splice(ItemIndex, 1);
  }
};

